import React from "react"
import { StaticQuery, graphql } from "gatsby"
// import {FaEnvelope, FaLocationArrow, FaFacebook, FaLinkedin} from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import SiteImg1 from "../assets/img/component-site.png"



const PrivacyPage = () => (
  <StaticQuery
    query={graphql`
      query{
        allStrapiPages{
          edges {
            node {
              PageTitle
            }
          }
        }
      }
    `}

    render={data => (
      
    <Layout type="privacy">
        <SEO title="Privacy Policy" />


        <section className="contact-form-section">

          <h1>Privacy Policy</h1>

          <div className="full-width-paragraph">
          last updated: 22nd December 2020<br/>

          <p>We want to make sure all the information we have collected about you is safe and secure whether we collect it through our website at www.fingersnap.it ("Site"), through our preview ("App") or API ("API"), from you via other electronic means (such as email or social media platforms), or in-person at our events.<br/>
          This website is not intended for children and we do not knowingly collect data relating to children.<br/>
It is important that you read this privacy policy together with any other privacy policy or fair processing policy we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy policy supplements other notices and privacy policies and is not intended to override them.
</p>

          <h3>Controller</h3>

          <p>Fingersnap ltd is the controller and responsible for your personal data.<br/>

Fingersnap is made up of different legal entities, details of which can be found here on our website. This privacy policy is issued on behalf of the Fingersnap Ltd so when we mention “Fingersnap”, “we”, “us” or “our” in this privacy policy, we are referring to the relevant company in the Fingersnap Ltd responsible for processing your data. <br/>

We have appointed a data protection officer (DPO) who is responsible for overseeing questions in relation to this privacy policy. If you have any questions about this privacy policy, including any requests to exercise your legal rights, please contact the DPO using the details following email address: dpo@fingersnap.it.<br/>

This policy shall not apply to any information (such as invoices or assets) uploaded to the Fingersnap platform by you, or on your behalf, containing personal data will be held by us as a data processor.  You are the data controller in respect of any personal data you (or someone on your behalf) upload onto the Fignersnap platform.<br/>

</p>
          
          <h3>1. What data we may collect</h3>
          <p>Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).<br/>

We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:<br/>
 - Identity Data includes: first name, last name, username or similar identifier, marital status, title, date of birth and gender.<br/>
 - Contact Data includes: job description, billing address, email address and telephone numbers.<br/>
 - Transaction Data includes: details about payments, receipts or invoices between you and third parties; details about payments to and from you and us and other details of products and services you have purchased from us.<br/>
 - Technical Data includes: internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website or our app.  We may receive Technical Data about you from analytics providers (such as Google based outside of the EU).<br/>
 - Profile Data includes: your username and password, purchases or orders made by you, your preferences, feedback and survey responses.<br/>
 - Usage Data includes: information about how you use our website, products and services.<br/>
 - Marketing and Communications Data includes: your preferences in receiving marketing from us and our third parties and your communication preferences.<br/>
 We also collect, use and share Aggregated Data such as statistical or demographic data for any purpose. Aggregated Data could be derived from your personal data but is not considered personal data in law as this data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy notice.<br/>
 We do not collect any special categories of personal data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data). Nor do we collect any information about criminal convictions and offences.<br/>
 Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with our services). In this case, we may have to cancel a service you have with us but we will notify you if this is the case at the time.
          </p>
          <h3>2. How is your personal data collected?</h3>
          <p>We use different methods to collect data from and about you including through:</p>
          <p>2.1. Direct interactions<br/>
          You may give us your Identity, Contact and Transaction Data by filling in forms or by corresponding with us by post, phone, email or otherwise. This includes, but is not limited to, personal data you provide when you:<br/>
          - subscribe to our products or services<br/>
          - apply for a demonstration of our products and services<br/>
          -	create an account on our website;<br/>
          - request marketing to be sent to you;<br/>
- apply to attend or take part in a webinar, training session or event, whether in person or online;<br/>
- enter a competition, promotion or survey; or<br/>
- give us feedback or contact us.
          </p>
          <p>2.2. Automated technologies or interactions<br/>
          As you interact with our website, we will automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies, server logs and other similar technologies. We may also receive Technical Data about you if you visit other websites employing our cookies.</p>
          <p>2.3. Third parties or publicly available sources<br/>
          We will receive personal data about you from various third parties and public sources. Technical Data from the following parties as set below:<br/>
          - analytics providers such as Google based outside the EU;<br/>
- advertising networks based inside or outside the EU; and<br/>
- search information providers based inside or outside the EU.<br/>
- Identity, Contact, Profile, Usage and Transaction Data from providers of technical, payment and delivery services such as Stripe, or Segment based inside or outside the EU.<br/>
- Identity and Contact Data from data brokers or aggregators based inside or outside the EU.<br/>
- Identity and Contact Data from publicly available sources such as Companies House and the Electoral Register based inside the EU.         
          </p>
          <h3>3. How do we use your personal data?</h3>
          <p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:<br/>
          -	Where we need to perform the contract we are about to enter into or have entered into with you.<br/>
- Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.<br/>
- Where we need to comply with a legal obligation. <br/>
Generally, we do not rely on consent as a legal basis for processing your personal data although we will get your consent before sending third party direct marketing communications to you via email or text message. You have the right to withdraw consent to marketing at any time by contacting us.<br/>
We have set out below a description of all the ways we plan to use your personal data, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate. <br/>
Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. Please contact us if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below.<br/>
          </p>
          <h3>4. Marketing</h3>
          <p>We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising<br/>
          </p>
          <p>4.1. Promotional offers from us<br/>
          We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you (we call this marketing).<br/>
          You will receive marketing communications from us if you have requested information from us or purchased services from us and you have not opted out of receiving that marketing.
          </p>
          <p>4.2. Third-party marketing<br/>
          We will get your express opt-in consent before we share your personal data with any third party for marketing purposes.</p>
          <p>4.3. Opting out<br/>
          You can ask us or third parties to stop sending you marketing messages at any time by following the opt-out links on any marketing message sent to you.  Where you opt-out of receiving these marketing messages, this will not apply to personal data provided to us as a result of your subscription to our services and products.
          </p>
          <h3>5. Who else receives your personal data?</h3>
          <p>We may share your personal data with the parties set out below:<br/>
          - Anyone you have said you are happy for us to send your details to.<br/>
- Organisations that provide services to us: such as marketing specialists, communication companies (such as Twilio to send SMS messages to you), customer relationship management platforms (such as Salesforce), the people that manage our website or support platform on our behalf, third party payment providers (such as Stripe), computer services (like our cloud storage services provider AWS and collaboration tools) and third-party mailing providers (such as Sendgrid, Mailchimp, ActiveCampaign).<br/>
- Organisations that we work with:  such as our business partners, which include integrators<br/>
- Internal third parties: our affiliated companies.<br/>
- Other third parties:  third parties to whom we may choose to sell, transfer or merge parts of our business or our assets.  Alternatively, we may seek to acquire other businesses or merge with them.  If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy notice.<br/>
- The Government: where the law tells us to do so or to help them with any investigations.<br/>
- Police, law enforcement and security services: to help them with any investigation, prevention of crime or matter of national security.<br/>
We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.
          </p>
          <h3>6. International transfers</h3>
          <p>Many of our external third parties are based outside the EEA so their processing of your personal data will involve a transfer of data outside the EEA. <br/>
Whenever we transfer your personal data out of the EEA, we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:<br/>
- We will only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data by the European Commission. For further details, <i>see European Commission: Adequacy of the protection of personal data in non-EU countries.</i><br/>
- Where we use certain service providers, we may use specific contracts approved by the European Commission which give personal data the same protection it has in Europe. For further details, <i>see European Commission: Model contracts for the transfer of personal data to third countries</i><br/>
- Where we use providers based in the US, we may transfer data to them if they are part of the Privacy Shield which requires them to provide similar protection to personal data shared between Europe and the US. For further details, <i>see European Commission: EU-US Privacy Shield.</i><br/>
Please contact us if you want further information on the specific mechanism used by us when transferring your personal data out of the EEA.
</p>
          <h3>7. How long will we keep your personal data for?</h3>
          <p>
          By law, we have to keep basic information about our customers (including Contact, Identity and Transaction Data) for six years after they cease being customers for tax purposes or after we were last in contact with you.<br/>
In some circumstances you can ask us to delete your data: <i>see Your rights to your personal data and complaints</i> below for further information.<br/>
In some circumstances, we will anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.
          </p>
          <h3>8. Your rights to your personal data and complaints</h3>
          <p>You're entitled to know what personal information we hold about you and how that information is processed, which means you can ask us to do any of the following things:<br/>
          -	to provide access to your data;<br/>
- to rectify any mistakes relating to your data;<br/>
- to erase your data;<br/>
- to stop processing your data;<br/>
- to receive an electronic copy of your data;<br/>
- to object to our processing of your data;<br/>
- to object to any automated decision making that we may do using your personal data; and<br/>
- (automatic) to receive a notification of any breach of your personal data.<br/>
Just let us know (see <i>Contact</i> section below) if you’d like to exercise any of your rights.<br/>
If you’re unhappy with how any access request has been dealt with, you have the right to complain to the Information Commissioner by calling their helpline: 0303 123 1113 or starting a live chat at  https://ico.org.uk/concerns/.<br/>
You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.<br/>
We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.<br/>
We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.

          </p>
          <h3>9. Changes to the privacy policy and your duty to inform us of changes</h3>
          <p>We keep our privacy policy under regular review. It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.
</p>
          <h3>10. Third-party links</h3>
          <p>This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy policy of every website you visit.</p>
          <h3>11. Contact</h3>
          <p>We welcome any queries or enquiries or requests in respect of this notice, you can contact us by sending us an email on: dpo@fingersnap.it.<br/>
Our company registration details are as follows:<br/>
Fingersnap Ltd (company registration number 12768556) Registered office: Hoghton Chambers, Hoghton Street, Southport, United Kingdom, PR9 0TB

</p>
          </div>
          
        </section>


      </Layout>

    )}
  
  />
)

export default PrivacyPage
